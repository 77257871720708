import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { checkCircleIcon } from "@debionetwork/ui-icons";
import emojis from "@/common/constants/menstrual-symptoms-emoji";
import moods from "@/common/constants/menstruation-moods";
import MenstrualCalendarBanner from "./Banner.vue";
import Calendar from "@/common/components/Calendar";
import { mapState } from "vuex";
import { getLastMenstrualCalendarByOwner, getMenstrualCalendarById, getLastMenstrualCalendarCycleLogByOwner, getMenstrualCycleLog } from "@/common/lib/polkadot-provider/query/menstrual-calendar";
import { addMenstrualCycleLog, updateMenstrualCycleLog } from "@/common/lib/polkadot-provider/command/menstrual-calendar";
import LoadingDialog from "@/common/components/Dialog/LoadingDialog.vue";
export default {
  name: "SelectEmoji",
  data: () => ({
    checkCircleIcon,
    emojis,
    moods,
    selectedMonthText: "",
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    selectedDates: null,
    submitEnabled: false,
    submitPreview: false,
    selectAverage: true,
    showStart: false,
    averageCycle: 0,
    monthList: [{
      value: 0,
      text: "January"
    }, {
      value: 1,
      text: "February"
    }, {
      value: 2,
      text: "March"
    }, {
      value: 3,
      text: "April"
    }, {
      value: 4,
      text: "May"
    }, {
      value: 5,
      text: "June"
    }, {
      value: 6,
      text: "July"
    }, {
      value: 7,
      text: "August"
    }, {
      value: 8,
      text: "September"
    }, {
      value: 9,
      text: "October"
    }, {
      value: 10,
      text: "November"
    }, {
      value: 11,
      text: "December"
    }],
    menstrualCalendarData: null,
    menstruationPeriodeIndex: [],
    todaySum: null,
    emojiDays: {},
    emojiSelected: [],
    days: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    descriptions: ["Today", "Menstruation", "Prediction", "Fertility", "Ovulation"],
    cycleLog: [],
    dataMenstrualCalanedar: null,
    emojiChangesUpdate: {},
    emojiChangesAdded: {},
    loadingSubmitChange: false,
    isSuccess: false,
    calendarLoading: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  watch: {
    async selectedMonthText(newMonth) {
      this.menstruationPeriodeIndex = [];
      this.selectedMonth = this.monthList.find(value => value.text === newMonth).value;
    },
    selectedDates(newSelected) {
      var _this$emojiDays$newSe;
      this.submitEnabled = newSelected !== null && newSelected.length > 0;
      this.emojiSelected = (_this$emojiDays$newSe = this.emojiDays[newSelected === null || newSelected === void 0 ? void 0 : newSelected.getTime()]) !== null && _this$emojiDays$newSe !== void 0 ? _this$emojiDays$newSe : [];
    },
    async emojiSelected() {
      this.updateEmojis();
    },
    async emojiDays() {
      await this.reRenderMenstrualCalendarData();
      this.updateEmojis();
    }
  },
  methods: {
    async prev() {
      let prevMonth = this.selectedMonth - 1;
      if (prevMonth < 0) {
        prevMonth = 11;
        this.selectedYear = this.selectedYear - 1;
      }
      this.selectedMonth = prevMonth;
      this.selectedMonthText = this.monthList[this.selectedMonth].text;
      await this.getData();
    },
    async next() {
      let nextMonth = this.selectedMonth + 1;
      if (nextMonth > 11) {
        nextMonth = 0;
        this.selectedYear = this.selectedYear + 1;
      }
      this.selectedMonth = nextMonth;
      this.selectedMonthText = this.monthList[this.selectedMonth].text;
      await this.getData();
    },
    async addEmoji(emoji) {
      if (!this.selectedDates) return;
      if (emoji.disabled === "active") {
        await this.removeSymptoms(this.selectedDates, emoji);
      } else {
        await this.addSymptoms(this.selectedDates, emoji);
      }
    },
    async addSymptoms(date, emoji) {
      var _emojiDaysCopy$time;
      let emojiDaysCopy = {
        ...this.emojiDays
      };
      const time = date.getTime();
      let emojiCollection = (_emojiDaysCopy$time = emojiDaysCopy[time]) !== null && _emojiDaysCopy$time !== void 0 ? _emojiDaysCopy$time : [];
      emojiCollection.push(emoji.name);
      emojiDaysCopy[time] = emojiCollection;
      this.emojiDays = emojiDaysCopy;
      await this.storeUpdated();
    },
    async removeSymptoms(date, emoji) {
      var _emojiDaysCopy$time$f, _emojiDaysCopy$time2;
      let emojiDaysCopy = {
        ...this.emojiDays
      };
      const time = date.getTime();
      emojiDaysCopy[time] = (_emojiDaysCopy$time$f = (_emojiDaysCopy$time2 = emojiDaysCopy[time]) === null || _emojiDaysCopy$time2 === void 0 ? void 0 : _emojiDaysCopy$time2.filter(val => val !== emoji.name)) !== null && _emojiDaysCopy$time$f !== void 0 ? _emojiDaysCopy$time$f : [];
      this.emojiDays = emojiDaysCopy;
      await this.storeUpdated();
    },
    getSummary() {
      if (this.todaySum.menstruation) return moods.MENSTRUATION(this.todaySum.days);
      if (this.todaySum.fertility) return moods.FERTILITY;
      if (this.todaySum.ovulation) return moods.OVULATION;
      return moods.NONE;
    },
    async getData() {
      this.calendarLoading = true;
      const menstrualCalendar = await getLastMenstrualCalendarByOwner(this.api, this.wallet.address);
      const data = await getMenstrualCalendarById(this.api, menstrualCalendar[0]);
      this.dataMenstrualCalanedar = data;
      const menstrualCycleLogByOwner = await getLastMenstrualCalendarCycleLogByOwner(this.api, menstrualCalendar[menstrualCalendar.length - 1]);
      const cycle = [];
      for (let i = 0; i < menstrualCycleLogByOwner.length; i++) {
        const test = await getMenstrualCycleLog(this.api, menstrualCycleLogByOwner[i]);
        cycle.push(test);
      }
      cycle.sort((a, b) => parseInt(a.date.replaceAll(",", "")) - parseInt(b.date.replaceAll(",", "")));
      this.cycleLog = cycle;
      await this.getMenstruationCalendarData();
      this.calendarLoading = false;
    },
    async reRenderMenstrualCalendarData() {
      try {
        const today = new Date();
        const firstDateCurrentMonth = new Date(this.selectedYear, this.selectedMonth, 1);
        const firstDateNextMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0);
        const dayFirstDateCurrentMonth = firstDateCurrentMonth.getDay() === 0 ? 6 : firstDateCurrentMonth.getDay() - 1;
        const dayFirstDateNextMonth = firstDateNextMonth.getDay() === 0 ? 6 : firstDateNextMonth.getDay() - 1;
        const startDate = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1));
        const endDate = new Date(this.selectedYear, this.selectedMonth + 1, 6 - dayFirstDateNextMonth);
        const menstrualCalendarData = {
          addressId: this.dataMenstrualCalanedar.addressId,
          averageCycle: this.dataMenstrualCalanedar.averageCycle,
          cycleLog: []
        };
        let date = startDate;
        let indexDate = 0;
        const emojiDays = {
          ...this.emojiDays
        };
        while (date.getTime() < endDate.getTime()) {
          date = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1) + indexDate);
          const log = this.cycleLog.filter(log => Number(log.date.replaceAll(",", "")) === date.getTime());
          const menstruation = log[0];
          const symptoms = emojiDays[date.getTime()];
          const isMenstruation = menstruation !== null && menstruation !== void 0 && menstruation.menstruation ? 1 : 0;
          let currentData = {
            date: date.getTime(),
            symptoms: symptoms,
            menstruation: isMenstruation
          };
          menstrualCalendarData.cycleLog.push(currentData);
          if (today.getDate() === date.getDate()) {
            this.todaySum = currentData;
          }
          indexDate++;
        }
        this.menstrualCalendarData = menstrualCalendarData;
      } catch (err) {}
    },
    async getMenstruationCalendarData() {
      try {
        const today = new Date();
        const firstDateCurrentMonth = new Date(this.selectedYear, this.selectedMonth, 1);
        const firstDateNextMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0);
        const dayFirstDateCurrentMonth = firstDateCurrentMonth.getDay() === 0 ? 6 : firstDateCurrentMonth.getDay() - 1;
        const dayFirstDateNextMonth = firstDateNextMonth.getDay() === 0 ? 6 : firstDateNextMonth.getDay() - 1;
        const startDate = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1));
        const endDate = new Date(this.selectedYear, this.selectedMonth + 1, 6 - dayFirstDateNextMonth);
        const menstrualCalendarData = {
          addressId: this.dataMenstrualCalanedar.addressId,
          averageCycle: this.dataMenstrualCalanedar.averageCycle,
          cycleLog: []
        };
        let date = startDate;
        let indexDate = 0;
        const emojiDays = {
          ...this.emojiDays
        };
        while (date.getTime() < endDate.getTime()) {
          var _emojiDays$date$getTi;
          date = new Date(this.selectedYear, this.selectedMonth, -(dayFirstDateCurrentMonth - 1) + indexDate);
          const log = this.cycleLog.filter(log => Number(log.date.replaceAll(",", "")) === date.getTime());
          const menstruation = log[0];
          emojiDays[date.getTime()] = menstruation ? menstruation.symptoms : (_emojiDays$date$getTi = emojiDays[date.getTime()]) !== null && _emojiDays$date$getTi !== void 0 ? _emojiDays$date$getTi : [];
          const symptoms = emojiDays[date.getTime()];
          const isMenstruation = menstruation !== null && menstruation !== void 0 && menstruation.menstruation ? 1 : 0;
          let currentData = {
            date: date.getTime(),
            symptoms: symptoms,
            menstruation: isMenstruation
          };
          menstrualCalendarData.cycleLog.push(currentData);
          if (today.getDate() === date.getDate()) {
            this.todaySum = currentData;
          }
          indexDate++;
        }
        this.menstrualCalendarData = menstrualCalendarData;
        if (this.emojiDays) {
          this.emojiDays = emojiDays;
        }
      } catch (err) {}
    },
    toSubscriptionSetting() {
      this.$router.push({
        name: "menstrual-calendar-subscription-setting"
      });
    },
    toMenstrualSelectionUpdate() {
      this.$router.push({
        name: "menstrual-calendar-selection-update"
      });
    },
    updateEmojis() {
      if (!this.selectedDates) return;
      const emojies = this.emojis.map(value => {
        const selectedEmojies = this.emojiDays[this.selectedDates.getTime()];
        const active = selectedEmojies.some(name => name === value.name);
        value.disabled = active ? "active" : "inactive";
        value.color = active ? "#E27625" : "#363636";
        return value;
      });
      this.emojis = emojies;
    },
    async storeUpdated() {
      if (!this.selectedDates) return;
      const filter = this.cycleLog.filter(val => {
        const date = val.date.split(",").join("");
        const selectedDate = this.selectedDates.getTime();
        return Number(date) === selectedDate;
      });
      const emoji = this.emojiDays[this.selectedDates.getTime()];
      if (filter.length > 0) {
        const emojiChangeUpdateCopy = {
          ...this.emojiChangesUpdate
        };
        emojiChangeUpdateCopy[this.selectedDates.getTime()] = {
          id: filter[0].id,
          menstrualCalendarId: filter[0].menstrualCalendarId,
          date: Number(filter[0].date.split(",").join("")),
          menstruation: filter[0].menstruation,
          symptoms: emoji,
          createdAt: filter[0].createdAt.replaceAll(",", ""),
          updatedAt: new Date().getTime()
        };
        this.emojiChangesUpdate = emojiChangeUpdateCopy;
      } else {
        const emojiChangesAddedCopy = {
          ...this.emojiChangesAdded
        };
        emojiChangesAddedCopy[this.selectedDates.getTime()] = {
          date: this.selectedDates.getTime(),
          symptoms: emoji,
          menstruation: false
        };
        this.emojiChangesAdded = emojiChangesAddedCopy;
      }
    },
    async submitEmojis() {
      if (this.loadingSubmitChange) return;
      this.loadingSubmitChange = true;
      const menstrualCalendar = await getLastMenstrualCalendarByOwner(this.api, this.wallet.address);
      let dataUpdate = [];
      for (const updateEmoji in this.emojiChangesUpdate) {
        dataUpdate.push(this.emojiChangesUpdate[updateEmoji]);
      }
      let dataAdded = [];
      for (const addedEmoji in this.emojiChangesAdded) {
        dataAdded.push(this.emojiChangesAdded[addedEmoji]);
      }
      updateMenstrualCycleLog(this.api, this.wallet, dataUpdate, async () => {
        addMenstrualCycleLog(this.api, this.wallet, menstrualCalendar[menstrualCalendar.length - 1], dataAdded, async () => {
          this.loadingSubmitChange = false;
          this.isSuccess = true;
        });
      });
    },
    backToDetail() {
      this.$router.push({
        name: "menstrual-calendar-detail"
      });
    }
  },
  async created() {
    const emojies = this.emojis.map(value => {
      value.disabled = "inactive";
      value.color = "#363636";
      return value;
    });
    this.emojis = emojies;
    const today = new Date();
    this.selectedMonthText = this.monthList[today.getMonth()].text;
    this.currentYear = today.getFullYear().toString();
    await this.getData();
  },
  components: {
    MenstrualCalendarBanner,
    Calendar,
    LoadingDialog
  }
};