//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SpinnerLoader from "@bit/joshk.vue-spinners-css.spinner-loader";
export default {
  name: "LoadingDialog",
  components: {
    SpinnerLoader
  },
  props: {
    show: Boolean,
    desc: String,
    msg: {
      type: String,
      default: "Loading..."
    }
  }
};